@import '../../scss/_mixins.scss';

.ds-pagination {
  &__pages {
    border-radius: 5px;
    overflow: hidden;
  }
  &__page-number {
    display: inline-flex;
    transition: all 0.25s linear;
    cursor: pointer;
    border: solid 1px var(--gray-color-20);
    border-radius: var(--border-radius);
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 5px;
    min-width: 40px;
    height: 40px;

    position: relative;
    text-align: center;
    vertical-align: middle;
    justify-content: center;

    a {
      display: block;
      padding: 7px 12px 3px;
    }

    &:hover,
    &--active {
      background-color: var(--primary-color);
      a {
        color: var(--ds-color-text-primary);
      }
    }
    &.prev {
      a {
        transform: rotate(180deg);
        transform-origin: center;
        position: relative;
        top: 0;
      }
    }
    &.next {
      span {
        position: relative;
        top: -4px;
      }
    }
    &.prev,
    &.next {
      line-height: 0.5;
      font-size: 1.5rem;
      color: var(--primary-color);
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:hover {
        a {
          color: var(--ds-color-text-primary);
        }
      }
    }
  }
}
