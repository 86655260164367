@import '../../scss/_breakpoints.scss';
@import '../../scss/_mixins';

.ds-person-card {
  --person-card-image-background: transparent;
  position: relative;
  background-color: transparent;
  max-width: 700px;

  @media screen and (max-width: 1024px) {
    --grid-row-gap: 30px;
    margin-bottom: var(--grid-row-gap);
    max-width: 100%;
  }

  h2,
  h2 a {
    font-size: var(--ds-typography-sizes-h-4-size);
    line-height: var(--ds-typography-sizes-h-4-line-height);
  }

  &--plum {
    --person-card-image-background: var(--ds-color-gradients-plum);
  }

  &--blue {
    --person-card-image-background: var(--ds-color-gradients-blue);
  }

  &--green {
    --person-card-image-background: var(--ds-color-gradients-green);
  }

  &--orange {
    --person-card-image-background: var(--ds-color-gradients-orange);
  }

  &__content {
    display: grid;
    grid-template-columns: auto 233px;
    border-radius: 10px;
    overflow: hidden;
    background-clip: padding-box;
    box-shadow: var(--ds-shadow);
    height: 100%;

    p,
    ul {
      margin-bottom: 0;
    }

    @include breakpoint(sm) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__text {
    padding: 50px;
    flex-direction: column;
    @include cardEtching();
    background-image: url(../../../public/large-card-back.jpg);
    @include breakpoint(sm) {
      padding: 55px 30px;
    }
  }

  &--normal {
    a {
      display: block;
      height: 100%;
    }
  }

  &__image {
    display: flex;
    align-items: flex-end;
    background: var(--person-card-image-background);
    height: 100%;
    a {
      height: 100%;
    }
    @include breakpoint(sm) {
      a {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    .ds-image {
      height: inherit;
      picture {
        height: inherit;
        img {
          height: inherit;
          display: block;
          object-fit: cover;
        }
      }
    }
  }
}
