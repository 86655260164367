@import '../../design-system/src/scss/breakpoints';

.featured-layout {
  &--with-large {
    --grid-size: 1;
    --grid-element-size: 100%;
    row-gap: 28px;
    --grid-row-gap: 28px;
  }

  &--side-by-side {
    --grid-size: 2;
    --grid-element-size: 100%;
    row-gap: 28px;
    --grid-row-gap: 28px;
  }

  &__the-rest {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }

  @include min-breakpoint(md) {
    &--with-large {
      --grid-size: 2;
      --grid-element-size: 50%;
    }
  }
}
