@import '../../design-system/src/scss/breakpoints';

.icon-text-block-layout {
  --gap: 184px;
  grid-template-columns: auto 320px;
  gap: var(--gap);
  box-sizing: border-box;

  &.large-side-right {
    grid-template-columns: 320px auto;
  }

  @include breakpoint(md) {
    display: block;
    margin-left: auto;
    margin-right: auto;

    & > div:first-child {
      margin-bottom: var(--spacer-10);
    }
  }
}
