@import '../../scss/breakpoints';

.ds-stat {
  --border-color: #3b3e41;
  border: solid 1px var(--border-color);
  border-radius: 5px;
  overflow: hidden;
  flex: 0 0 100%;

  &--large {
    min-height: 590px;
    height: 100%;
    width: 100%;
    .ds-stat__value {
      font-size: 7.5rem;
      margin-bottom: var(--spacer-9);
    }
    .ds-card {
      justify-content: center;
      padding-top: var(--spacer-6);
    }
  }

  .ds-card {
    min-height: 0;
    &__icon {
      min-height: 0;
    }
    p {
      line-height: 1.5;
    }
  }

  &__value {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: var(--spacer-5);
  }

  &__label {
    font-size: 1.5rem;
    font-weight: 600;
    color: #3b3e41;
  }

  &--plum .ds-stat__value {
    color: var(--ds-color-primary-light);
  }

  &--blue .ds-stat__value {
    color: var(--ds-color-secondary);
  }

  &--green .ds-stat__value {
    color: var(--ds-color-tertiary);
  }

  &--orange .ds-stat__value {
    color: var(--ds-color-accent);
  }

  @include min-breakpoint(md) {
    flex: 0 0 calc(50% - 32px);
    &--large {
      max-width: 100%;
    }
  }

  .ds-card {
    height: 100%;
    max-width: none;
  }
}
