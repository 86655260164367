.board-links {
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  z-index: 4;

  &__section {
    padding: 16px 0;
    border-bottom: 1px solid #ddd;
  }

  h3 {
    margin: 0;
    line-height: 0;
    a {
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }

  p {
    margin-bottom: 0;
    a {
      font-size: 0.875rem;
    }
  }
}
