.ds-large-icon {
  position: relative;
  display: inline-flex;
  place-content: center;
  width: 60px;
  height: 60px;
  background-color: #fff;

  &::before {
    position: absolute;
    content: '';
    left: -5px;
    top: -5px;
    width: 70px;
    height: 70px;
    z-index: 0;
    background-color: #f6f6f6;
    border-radius: 50%;
  }

  img,
  svg,
  .ds-large-icon__svg {
    position: relative;
    z-index: 1;
    width: inherit;
    height: inherit;
  }

  // &::after {
  //   position: absolute;
  //   content: '';
  //   width: 60px;
  //   height: 60px;
  //   left: 0;
  //   top: 0;
  //   mix-blend-mode: screen;
  //   z-index: 1;
  //   overflow: hidden;
  // }

  &--small {
    width: 45px;
    height: 45px;

    &::before {
      left: -2px;
      top: -2px;
      width: 49px;
      height: 49px;
    }

    &::after {
      width: 45px;
      height: 45px;
    }
  }

  &--blue {
    svg {
      path {
        fill: var(--ds-color-secondary);
      }
    }
  }

  &--green {
    svg {
      path {
        fill: var(--ds-color-tertiary);
      }
    }
  }

  &--orange {
    svg {
      path {
        fill: var(--ds-color-accent);
      }
    }
  }

  &--plum {
    svg {
      path {
        fill: var(--ds-color-primary-light);
      }
    }
  }

  &--primaryLight {
    svg {
      path {
        fill: var(--ds-color-primary-light);
      }
    }
  }

  // &--blue::after {
  //   background-color: var(--ds-color-secondary);
  // }

  // &--green::after {
  //   background-color: var(--ds-color-tertiary);
  // }

  // &--orange::after {
  //   background-color: var(--ds-color-accent);
  // }

  // &--plum::after {
  //   background-color: var(--ds-color-primary-light);
  // }
}
