.ds-supporting-content {
  position: relative;

  p {
    margin-bottom: var(--spacer-5);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
