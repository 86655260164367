.ds-text-block {
  &__body {
    ul,
    ol {
      list-style: disc;
      margin-left: 20px;

      li {
        margin-bottom: 12px;
      }
    }
    ol {
      list-style: decimal;
    }
  }
}
