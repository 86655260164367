@import '../../scss/_breakpoints.scss';

.ds-two-thirds {
  --gap: 48px;
  --minus: calc(var(--gap) / 2);
  grid-template-columns: 55% auto;
  gap: var(--gap);
  box-sizing: border-box;

  &--large-side-right {
    grid-template-columns: auto 55%;
  }

  @include breakpoint(md) {
    --grid-row-gap: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    & > div:first-child {
      margin-bottom: var(--spacer-10);
    }
  }
}
