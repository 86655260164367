@import '../../scss/_breakpoints.scss';

.ds-image-grid{
    text-align: center;
    @include breakpoint(sm) {
        .flex-container {
            flex-direction:column;
        }
    }
}
