@import '../../scss/_breakpoints.scss';
@import '../../scss/_mixins';

.ds-card {
  //min-height: var(--min-card-height);
  //width: var(--ds-card-width);
  --shadow: var(--ds-shadow);
  --card-top-padding: var(--spacer-6);
  flex-direction: column;
  position: relative;
  display: flex;
  padding: 0 0 var(--spacer-2);
  text-align: center;
  justify-self: stretch;
  max-width: 500px;
  &:not(.logo) {
    @include cardEtching();
  }

  &--with-cta {
    padding: 0 0 var(--spacer-6);
  }

  h2,
  h2 a {
    font-size: var(--ds-typography-sizes-h-4-size);
    line-height: var(--ds-typography-sizes-h-4-line-height);
  }

  &.article {
    h2,
    h2 a {
      min-height: 5.5rem;
    }
  }

  &.icon {
    align-items: center;
    // min-height: 447px;
  }

  &.image,
  &.location {
    --card-top-padding: var(--spacer-4);
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    iframe {
      border: none;
      object-fit: contain;
    }
  }

  &.logo {
    --card-top-padding: var(--spacer-3);
    padding-bottom: 0;
    max-height: 200px;
    display: flex;
    place-items: center;
    picture,
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }

  .flexible {
    min-height: 0;
  }

  @include cardShadow();

  &__image {
    height: 140px;
    width: 100%;
    max-height: 100%;
    overflow: hidden;

    picture,
    img {
      width: 100%;
      height: 140px;
      object-fit: cover;
    }

    iframe {
      border: none;
    }
  }

  // .ds-card__text {
  //   margin-bottom: var(--spacer-4);
  // }

  &.news {
    .ds-card__text {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .ds-card__text-subtitle {
    //color: var(--primary-color-lightest);
    margin-bottom: var(--spacer-1);
    line-height: 1.5;
    font-size: 0.875rem;
  }

  .ds-card__footer {
    --card-footer-items: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(var(--card-footer-items), auto);
  }

  &__text {
    width: 100%;
    padding: var(--card-top-padding) var(--spacer-3, 16px) 0;
    p {
      line-height: 1.5;
    }

    @include breakpoint(md) {
      padding: var(--spacer-4, 16px) var(--spacer-2, 16px) 0;
    }
  }
  &.gutter-card {
    &__text {
      padding-top: 16px;
    }
  }

  &__tags {
    margin-top: var(--spacer-4);
    display: flex;
    align-items: flex-start;
  }

  &.accent {
    background-color: var(--ds-color-accent);
    @include selectFontColor(var(--ds-color-text-accent-foreground));
  }
}

.ds-card-container--large {
  .ds-card {
    background-image: url(../../../public/large-card-back.jpg);
  }
}
