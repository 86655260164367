@import '../../scss/_breakpoints.scss';
@import '../../scss/_mixins.scss';

.ds-image--square {
  position: relative;
  border-radius: 10px;
  overflow: visible;
  height: 468px;

  @include breakpoint(md) {
    max-height: 294px;
    max-width: 522px;
  }

  &::before {
    box-shadow: var(--ds-shadow);
    border-radius: 10px;
    overflow: hidden;
  }

  img {
    border-radius: 10px;
    overflow: hidden;
    display: block;
    object-fit: cover;
    object-position: center;
  }

  @include etchedBackgroundFull(rgba(0, 0, 0, 0.25), 0.75, cover);
}
