.ds-card-container {
  position: relative;
  z-index: 4!important;
  &::before,
  &::after {
    content: '';
    width: 100%;
    order: 1;
  }

  .ds-card-container__card {
    flex-basis: 320px;
    max-width: 320px;

    @media screen and (max-width: 1024px) {
      flex-basis: 300px;
      max-width: 300px;
    }
  }

  .ds-card-container__card--large {
    flex-basis: 499px;
    max-width: 499px;
  }

  &--large {
    .ds-card-container__card {
      flex-basis: 499px;
      max-width: 499px;
    }
  }

  &--five {
    .ds-card-container__card:nth-child(n + 4) {
      order: 1;
    }
  }

  .anchor {
    position: absolute;
    bottom: 50px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    &.external {
      bottom: var(--spacer-3);
    }
  }
}

.subheadline {
  ul,
  ol {
    list-style: disc;
    margin-left: 20px;

    li {
      margin-bottom: 12px;
    }
  }
  ol {
    list-style: decimal;
  }
}
