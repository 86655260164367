@import '../../scss/_breakpoints.scss';

.ds-sixty-fourty {
  --gap: 48px;
  grid-template-columns: 63% auto;
  gap: var(--gap);
  box-sizing: border-box;

  &.large-side-right {
    grid-template-columns: auto 63%;
  }

  @media screen and (max-width: 1024px) {
    --grid-row-gap: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    & > div:first-child {
      margin-bottom: var(--spacer-10);
    }

    text-align: center;
  }
}
