@import '../../scss/breakpoints';

.ds-pull-quote {
  max-width: 715px;
  margin: 0 auto;
  grid-template-columns: minmax(150px, 1fr) auto;
  position: relative;

  @include breakpoint(sm) {
    text-align: center;
  }

  &__image {
    position: relative;
  }

  // &__image::after {
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   mix-blend-mode: screen;
  //   z-index: 1;
  // }

  &__image--plum path {
    fill: var(--ds-color-primary-light);
  }

  &__image--blue path {
    fill: var(--ds-color-secondary);
  }

  &__image--green path {
    fill: var(--ds-color-tertiary);
  }

  &__image--orange path {
    fill: var(--ds-color-accent);
  }
}

.ds-quote {
  &--highlight {
    font-weight: 600;
    font-size: 1.25em;
    color: var(--ds-color-text-heading);
    font-style: normal;
  }

  @include breakpoint(sm) {
    text-align: center;
  }
}
