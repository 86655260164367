@import '../../scss/_breakpoints.scss';
@import '../../scss/_mixins.scss';

.ds-cta-banner {
  --overlay-size: cover;
  --overlay-color: transparent;
  --overlay-opacity: 0.42;
  border-radius: var(--border-radius);
  min-height: 400px;
  max-width: 900px;
  padding: var(--spacer-10) 0;
  text-align: center;
  color: #fff;
  position: relative;
  box-shadow: var(--shadow);

  @include breakpoint(md) {
    padding: var(--spacer-10) 0;
    margin: 0 20px;
  }

  &--primary {
    background-color: var(--primary-color);
  }

  &--secondary {
    background-color: var(--secondary-color);
  }

  &--tertiary {
    background-color: var(--accent-color);
  }

  &--plum {
    background: var(--ds-color-gradients-plum);
  }

  &--blue {
    background: var(--ds-color-gradients-blue);
  }

  &--green {
    background: var(--ds-color-gradients-green);
  }

  &--orange {
    background: var(--ds-color-gradients-orange);
  }

  p {
    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: rgb(255, 255, 255, 0.5);
      }
    }
  }

  &--image,
  &--background-image {
    --overlay-opacity: 0.8;
    --overlay-color: rgba(0, 0, 0, 0.75);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    mix-blend-mode: multiply;
  }

  &__content {
    padding: 0 var(--spacer-10);
    position: relative;
    z-index: 1;

    @include breakpoint(sm) {
      padding: 0 var(--spacer-5);
    }
  }

  &--has-cutout {
    .ds-cta-banner__content {
      width: 66%;
      text-align: left;
      @include breakpoint(sm) {
        width: 100%;
        margin-bottom: var(--spacer-8);
      }
      &.right {
        float: right;
        text-align: right;

        @include breakpoint(sm) {
          float: none;
          text-align: left;
        }
      }
    }

    @include breakpoint(sm) {
      position: relative;

      &__content {
        padding: 0 0;
      }

      padding-bottom: 0;
    }
    .cutout {
      position: absolute;
      bottom: 0;
      right: 0;
      // max-height: 564px;
      max-height: calc(100% + 30px);
      display: block;

      &.right {
        left: 0;
        right: auto;
      }
      @include breakpoint(sm) {
        position: relative;
        bottom: auto;
        right: auto;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &--default {
    --overlay-size: cover;
  }

  @include etchedBackgroundFull(
    #{var(--overlay-color)},
    var(--overlay-opacity),
    var(--overlay-size)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: pre-line;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .intro-paragraph {
    color: #fff;
  }
  .clearfix {
    clear: both;
  }
}
